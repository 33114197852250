import React, {createContext, FC, ReactNode, useEffect, useMemo, useState} from 'react';
// import {fireAuth} from "../../firebase";
import {EAppView} from "../../types/EView";

interface IContextData {
    // isAuthed: boolean;
    // userId: string | null;
    appView: EAppView;
    recordedAudio?: Blob;
    setAppView: (val: EAppView) => void;
    setRecorderAudio: (val: Blob | undefined) => void;
}

const initData: IContextData = {
    // isAuthed: false,
    // userId: null,
    recordedAudio: undefined,
    appView: EAppView.book,
    setAppView: () => {},
    setRecorderAudio: () => {}
};

export const AppContext = createContext(initData);


interface IDeviceContextProviderProps {
    children: ReactNode;
}

const AppContextProvider: FC<IDeviceContextProviderProps> = ({children}: IDeviceContextProviderProps) => {
    // const [isAuthed, setIsAuthed] = useState<boolean>(false);
    // const [userId, setUserId] = useState<string | null>(null);
    const [appView, setAppView] = useState<EAppView>(EAppView.book);
    const [recordedAudio, setRecorderAudio] = useState<Blob>();

    // useEffect(() => {
    //     fireAuth.signInAnonymously();
    //     fireAuth.onAuthStateChanged((user) => {
    //         if (user) {
    //             setIsAuthed(true)
    //             setUserId(user.uid);
    //         } else {
    //             setIsAuthed(false);
    //             setUserId(null);
    //         }
    //     })
    // }, [])

    useEffect(() => {
        const sticker = localStorage.getItem('sticker');
        const bookId = localStorage.getItem('bookId');
        if (sticker) setAppView(EAppView.addBook);
        if (bookId) setAppView(EAppView.book);
    }, [])

    return (
        <AppContext.Provider
            value={useMemo(
                () => ({appView, recordedAudio, setAppView, setRecorderAudio}),
                [appView, recordedAudio, setAppView, setRecorderAudio],
            )}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;