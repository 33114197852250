import React, {FC, lazy, Suspense, useContext} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import './firebase';
import AppContextProvider, {AppContext} from "./contexts/App";
import {EAppView} from "./types/EView";

const Book = lazy(() => import("./pages/Book"));
const AddBook = lazy(() => import("./pages/AddBook"));

const App: FC = () => {
    const {appView} = useContext(AppContext);

    return (
        <Suspense fallback={null}>
            {
                appView !== EAppView.addBook && (
                    <Book/>
                )
            }
            {
                appView === EAppView.addBook && (
                    <AddBook/>
                )
            }
        </Suspense>
    )
}

const AppWithProvider: FC = () => <AppContextProvider><App/><ToastContainer hideProgressBar autoClose={10000}
                                                                            closeButton={false}/></AppContextProvider>;

export default AppWithProvider;
